import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DefaultComponent } from './layouts/default/default.component';
import { SignInComponent } from './modules/sign-in/sign-in.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { TrucksComponent } from './modules/trucks/trucks.component';
import { TruckTypesComponent } from './modules/truck-types/truck-types.component';
import { AlertsComponent } from './modules/alerts/alerts.component';
import { StatusComponent } from './modules/status/status.component';
import { PublicMapSettingsComponent } from './modules/public-map-settings/public-map-settings.component';
import { LogosComponent } from './modules/logos/logos.component';
import { PortalsComponent } from './modules/portals/portals.component';
import { StreetPriorityComponent } from './modules/street-priority/street-priority.component';
import { UsersComponent } from './modules/users/users.component';
import { PublicComponent } from './modules/public/public.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ChartReportComponent } from './modules/chart-report/chart-report.component';
import { StatisticsComponent } from './modules/statistics/statistics.component';
import { InstructionComponent } from './modules/instruction/instruction.component';
import { StreetHighwayChangeComponent } from './modules/street-highway-change/street-highway-change.component';
import { LoadStreetComponent } from './modules/load-street/load-street.component';
import { JobsListComponent } from './modules/jobs-list/jobs-list.component';
import { CanDeactivateGuard } from 'src/utils/can-deactivate.guard';
import { AllOrgsComponent } from './modules/all-orgs/all-orgs.component';
import { MoreInfoComponent } from './modules/more-info/more-info.component';
import { RouteMapComponent } from './modules/route-map/route-map.component';
import { TransportPublicComponent } from './modules/transport-public/transport-public.component';
import { ServiceControlComponent } from './modules/service-control/service-control.component';
import { JasperReportComponent } from './modules/jasper-report/jasper-report.component';

const children = [];
const publicChildren = [];
children.push({ path: '', redirectTo: '/Admin/Report', pathMatch: 'full' });
children.push({ path: 'Report', component: ReportsComponent});
children.push({ path: 'Zone', component: ReportsComponent,canDeactivate: [CanDeactivateGuard]});
children.push({ path: 'Reports', component: ChartReportComponent});
children.push({ path: 'Trucks', component: TrucksComponent});
children.push({ path: 'Transport-route', component: RouteMapComponent});
children.push({ path: 'TruckTypes', component: TruckTypesComponent});
children.push({ path: 'AlertMessages', component: AlertsComponent});
children.push({ path: 'EditStatus', component: StatusComponent});
children.push({ path: 'PublicMessage', component: PublicMapSettingsComponent});
children.push({ path: 'AddLogo', component: LogosComponent});
children.push({ path: 'Portals', component: PortalsComponent});
children.push({ path: 'Instructions', component: InstructionComponent});
children.push({ path: 'EditStreetPriority', component: StreetPriorityComponent});
children.push({ path: 'More-Information', component: MoreInfoComponent});
children.push({ path: 'Users', component: UsersComponent});
children.push({ path: 'Statistics', component: StatisticsComponent});
children.push({ path: 'EditStreet', component: StreetHighwayChangeComponent});
children.push({ path: 'load-street', component: LoadStreetComponent});
children.push({ path: 'jobs', component: JobsListComponent});
children.push({ path: 'Dashboard', component: AllOrgsComponent});
children.push({ path: 'Service-control', component: ServiceControlComponent});
children.push({ path: 'Detailed-reports', component: JasperReportComponent});
// children.push({ path: 'public/:id/:id', component: PublicComponent});
// children.push({ path: 'login', component: SignInComponent });

const routes: Routes =
[
  {
    path: '',
  redirectTo: '/login',
  pathMatch: 'full'},
  {
  path: 'Admin',
  component: DefaultComponent,
  canDeactivate: [CanDeactivateGuard],
  children
  },
  {
  path: 'login',
  component: SignInComponent,
  // children
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    // children
    },
  {
  path: 'public/:id/:id',
  component: PublicComponent,
  },
  {
  path: 'Public/:id/:id',
  component: PublicComponent,
  },
  {
    path: 'transport/:id/:id',
    component: TransportPublicComponent,
    }];

@Injectable()
export class MyStrategy extends RouteReuseStrategy {
   shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    return null;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }
}
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' , onSameUrlNavigation: 'reload'})],
  providers: [
    {provide: RouteReuseStrategy, useClass: MyStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
