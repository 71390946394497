import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession, IMfaSettings } from 'amazon-cognito-identity-js';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/utils/auth.service';
import { colorConfig } from 'src/environments/color-configs';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmOkDialogComponent } from 'src/app/shared/components/confirm-ok-dialog/confirm-ok-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {

  // username = '';
  // password = '';
  // username = 'bjohn';
  // password = 'John@1234';
  showNewPassword = false;
  showLoginForm = true;
  currentSession: any;
  cognitoUser: any;
  IMfaSettings:IMfaSettings ={
		PreferredMfa: true,
		Enabled: true
	}
  showMfaCode = false;
  secretCode: any;
  qrUri:string;
  authCode = '';
  showAuthCode=false;
  mfaType: string = "";
  userAttributes: any;
  requiredAttributes: any;
  forcePwdForm: NgForm
  selectMfaTypeForm: NgForm;
  smsMfaOtpForm: NgForm;
  mfaTypeSelected = '';
  smsOtp: any = '';
  secondsLeft: number = 30;
  newPassword = '';
  confirmPassword = ''
  rememberMe = false;

  isLoading = false;
  email_address = '';
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$";
  passwordPattern = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-zd$@#$!%*?&].{7,15}"
  resetSubmitted = false;
  showError = false;
  password = '';
  domain = 'snowpaths';
  loginBtnBg = '#f1772e';
  backendVersion = '';
  userPoolConfigs = {};
  splashCheck:any = '';
  orgId: any = null;
  pilotMode: number = null
  showLoading: boolean = false;
  newTruckAlert: any = [];
  showMfaTypeSelect: boolean = false;
  availableMfaTypes: any = [];
  intervalId: any;
  isButtonDisabled:boolean = false
  showSmsMfaForm: boolean = false;
  

  constructor(private apiService: ApiService, private helper: HelperService, 
    private router: Router, private route: ActivatedRoute, private location: Location,
    private authService: AuthService, private dialog: MatDialog, private cookieService: CookieService) {
    const urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isAvailable) {
      this.domain = 'streetpaths';
    }
    if (isFleetpathAvailable) {
      this.domain = 'fleetpaths';
    }
    this.loginBtnBg = colorConfig[this.domain]['loginBtnBackground'];
  }

  async ngOnInit() {
    // this.username='';
    // this.password='';
    // this.router.navigate(['/landing-page']);
    this.splashCheck = this.cookieService.get('splash');
    if(!this.splashCheck){
      this.showWelcomeSplash();
    }
    this.getVersion();
  }
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  async getVersion() {
    var url = 'version';
    await this.apiService.getVersion(url).subscribe((data: any) => {
      this.backendVersion = data;
      this.apiService.addLocalStorage('Backend', data['Title'])
      this.apiService.addLocalStorage('BackendVersion', data['Version'])
      this.getPoolConfigs();
    }, (error) => {
      console.log(error);
      this.getVersion();
    })
  }
  async getPoolConfigs() {
    var url = 'cognitoUserPool';
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      this.userPoolConfigs = data;
      var userData = JSON.stringify(data)
      this.apiService.addLocalStorage("poolData", btoa(userData))
      // console.log(atob(userData))
      // this.apiService.addLocalStorage("")
    }, (error) => {
      console.log(error);
    })
  }

  onSignIn(form: NgForm) {
    this.showLoading=true;
    const domain = this.email_address.slice(
      this.email_address.indexOf('@') + 1,
      this.email_address.lastIndexOf('.'),
    ).toLowerCase();
    console.log(domain)
    this.apiService.addLocalStorage('userPool',domain)
    if (form.valid) {
      this.isLoading = true;
      const authenticationDetails = new AuthenticationDetails({
        Username: this.email_address.toLowerCase(),
        Password: this.password,
      });
      if (this.userPoolConfigs && this.userPoolConfigs[domain] || this.userPoolConfigs[domain.toLowerCase()]) {
        const poolData = {
          UserPoolId: this.userPoolConfigs[domain.toLowerCase()].userPoolId, // Your user pool id here
          ClientId: this.userPoolConfigs[domain.toLowerCase()].clientId // Your client id here
        };

        const userPool = new CognitoUserPool(poolData);
        const userData = {
          Username: this.email_address.toLowerCase(),
          Pool: userPool,
        };
        this.cognitoUser = new CognitoUser(userData);
        this.cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result:CognitoUserSession,userConfirmationNecessary?:boolean) => {
            let tokenValue = jwt_decode<JwtPayload>(result.getIdToken().getJwtToken());
            this.apiService.setAuthResult(result);
            this.apiService.addLocalStorage('username', this.email_address.toLowerCase());
            this.apiService.addLocalStorage('domain', this.domain);
            this.apiService.addLocalStorage('role', tokenValue['custom:role'])
            this.orgId = tokenValue['custom:organizationid'];
            if(tokenValue['custom:role'] === "Admin"){
              this.getNewTruckDetails();
            }
            this.getOrgDetails();
            this.updateLoginDetails();
            this.getClientMessage();
            // this.helper.openSnackBar("Sign-in successfully!", "Ok")
          },
          onFailure: (err) => {
            this.helper.openSnackBar(err.message, "Ok")
            this.isLoading = false;
          },
          newPasswordRequired: (userAttributes, requiredAttributes,) => {
            delete userAttributes.email_verified;
            this.userAttributes = userAttributes
            this.requiredAttributes = requiredAttributes;
            this.showNewPassword = true;
            this.showLoginForm = false;
          },
          mfaRequired: (challengeName: any, challengeParameters: any) => {
            console.log("mfaRequired function ! "+challengeName)
            this.secondsLeft = 30;
            this.showMfaTypeSelect = false;
            this.showSmsMfaForm = true;
            this.startCountdown();
          },
          totpRequired: (challengeName: any, challengeParameters: any) => {
            this.mfaType = challengeName;
            this.showAuthCode=false;
            this.showLoginForm = false;
            this.showMfaCode = true;
          },
          customChallenge: (challengeParameters: any) => {
            console.log(challengeParameters)
          },
          mfaSetup: (challengeName, challengeParameters) => {
            console.log(challengeName +"+"+challengeParameters)
            this.cognitoUser.associateSoftwareToken({
              associateSecretCode: (secretCode) => {
                this.secretCode = secretCode;
                this.qrUri = `otpauth://totp/`+this.email_address.toLowerCase()+`?secret=${secretCode}`
                this.showLoginForm=false;
                this.showAuthCode = true
              },
              onFailure: (err: any) => {
                this.helper.openSnackBar(err.message,"Ok")
              }
            })
          },
          selectMFAType: (challengeName:any, challengeParameters:any) => {
            console.log("selectMFAType hit")
            this.showLoading=false;
            this.showLoginForm = false;
            if(this.showMfaCode){
              this.authCode = '';
              this.showMfaCode = false
            }
            this.showMfaTypeSelect = true;
            this.availableMfaTypes = JSON.parse(challengeParameters.MFAS_CAN_CHOOSE)
          },
        });
      } else if(!this.userPoolConfigs && !this.userPoolConfigs[domain]){
        this.helper.openSnackBar("Username is not valid", "Ok")
      }else{
        console.log("try again")
        this.helper.openSnackBar("User does not exist", "Ok")
      }
    } else {
      console.log('invalid');
    }
  }
  async getClientMessage() {
    var url = 'clientMessage';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      if(data && data[0]){
        this.showSiteMessage(data[0])
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  removeQueryParamsAndNavigate() {
    // Get the current URL tree
    const urlOrigin = window.location.href;

    if(urlOrigin.includes('Zone')){
      const currentUrlTree = this.router.createUrlTree([], { relativeTo: this.route });
      
      // Navigate to the updated URL without query parameters
      this.router.navigateByUrl(currentUrlTree);
    }
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.pilotMode = data.organization['Pilot'];
      if(this.pilotMode === 1){
        this.getPilotStatus();
      } else {
        this.router.navigate(['/Admin/Report'],{ queryParams: {}});
        this.removeQueryParamsAndNavigate();
        this.apiService.addLocalStorage('isAuthenticated', true);
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getNewTruckDetails() {
    var url = 'newTruckAlertMsg';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if(data.length > 0){
        this.newTruckAlert = data;
        this.showNewTruckAlert();
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPilotStatus() {
    var url = 'checkPilotExpiry';
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.apiService.addLocalStorage('isAuthenticated', true);
      this.router.navigate(['/Admin/Report'],{ queryParams: {}});
      this.removeQueryParamsAndNavigate();
    }, (error) => {
      console.log(error);
      this.showPilotError(error);
    })
  }
  async showSiteMessage(siteMsg) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Site status',
        message: siteMsg['Message']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }
  async showNewTruckAlert() {
    var title = 'New vehicle alert';
    
      const modalData = {};
      modalData['newTruckDetails'] = this.newTruckAlert;
      modalData['username'] = this.email_address;
      const confirmDialog = this.dialog.open(EditComponent, {
        data: {
          title: title,
          data: modalData,
          type: 'newTruckAlert'
        },
        minWidth: 250
      });
      confirmDialog.afterClosed().subscribe(result => {
        
      });
  }
  async showPilotError(message) {
    const confirmDialog = this.dialog.open(ConfirmOkDialogComponent, {
      data: {
        title: 'Alert',
        message: message
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      this.showLoading=false;
    });
  }
  async selectMfa() {
    this.cognitoUser.getAttributeVerificationCode('email', {
      onSuccess: function(result) {
        console.log('call result: ' + result);
      },
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
      },
      inputVerificationCode: function() {
        var verificationCode = prompt('Please input verification code: ', '');
        this.cognitoUser.verifyAttribute('email', verificationCode, this);
      },
    });
    
  }
  async onForcePwd(form: NgForm) {
    this.resetSubmitted = true;
    if (form.valid) {
      this.isLoading = true;
      this.cognitoUser.completeNewPasswordChallenge(this.newPassword, this.requiredAttributes, {
        onSuccess: (result) => {
          let tokenValue = jwt_decode<JwtPayload>(result.getIdToken().getJwtToken());
          this.apiService.setAuthResult(result);
          // this.apiService.addLocalStorage('isAuthenticated', true);
          this.apiService.addLocalStorage('username', this.email_address.toLowerCase());
          this.apiService.addLocalStorage('domain', this.domain);
          this.apiService.addLocalStorage('role', tokenValue['custom:role']);
          this.orgId = tokenValue['custom:organizationid'];
          this.getClientMessage()
          if(tokenValue['custom:role'] === "Admin"){
            this.getNewTruckDetails();
          }
          this.getOrgDetails();
        },
        onFailure: (err) => {
          this.helper.openSnackBar(err, "Ok")
        },
        mfaSetup: (challengeName, challengeParameters) => {
          this.cognitoUser.associateSoftwareToken({
            associateSecretCode: (secretCode) => {
              console.log(secretCode);
              this.secretCode = secretCode;
              this.qrUri = `otpauth://totp/`+this.email_address.toLowerCase()+`?secret=${secretCode}`
              this.showLoginForm=false;
              this.showNewPassword=false;
              this.showAuthCode = true;
            },
            onFailure: (err: any) => {
              this.helper.openSnackBar(err.message,"Ok")
            }
          })
        },
        mfaRequired: (challengeName: any, challengeParameters: any) => {
          console.log("mfaRequired function ! "+challengeName)
          this.secondsLeft = 30;
          this.showMfaTypeSelect = false;
          this.showNewPassword = false;
          this.showSmsMfaForm = true;
          this.startCountdown();
        },
      });
    } else {
      this.showError = true;
    }
  }
  async onSubmitMfaType(form: NgForm){
    this.resetSubmitted = true;
    if (form.valid) {
      this.isLoading = true;
      
      this.cognitoUser.sendMFASelectionAnswer(this.mfaTypeSelected, {
        onSuccess: function(result) {
          console.log('call result: ' + result);
        },
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
        },
        totpRequired: (challengeName: any, challengeParameters: any) => {
          this.mfaType = challengeName;
          this.showAuthCode=false;
          this.showMfaTypeSelect = false;
          this.showLoginForm = false;
          this.showMfaCode = true;
        },
        mfaRequired: (challengeName: any, challengeParameters: any) => {
          console.log("mfaRequired function ! "+challengeName)
          this.secondsLeft = 30;
          this.showMfaTypeSelect = false;
          this.showSmsMfaForm = true;
          this.startCountdown();
        },
      });
    }else {
      this.showError = true;
    }
  }
  async resendOtp(){
      this.cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);
        });
        this.secondsLeft = 30;
        this.startCountdown();
  }

  async logoutUser($event) {
    console.log('signing out the user ');
    const token = this.apiService.getLocalStorage('token');
    this.apiService.addLocalStorage('username', null);

    await this.apiService.logout(token).subscribe(
      () => {
        this.apiService.addLocalStorage('isAuthenticated', false);
        this.router.navigate(['/Admin/login']);
      }, (error) => {
        console.log(error);
      }
    );
  }

  isAuthenticated() {
    const res = this.helper.isAuthenticated();
    if (res) {
      this.router.navigate(['/Admin/Report'],{ queryParams: {}});
      this.removeQueryParamsAndNavigate();
    }
    return this.helper.isAuthenticated();
  }
  registerSecretCode(){
      this.cognitoUser.verifySoftwareToken(this.authCode, 'My TOTP device', {
      onSuccess: (session:CognitoUserSession) => {
        this.submitCode();
        this.authCode = '';
        this.helper.openSnackBar("Device registered successfully & enter new code again to login","Ok")
        console.log('call result: ' + session);
      },
      onFailure: (err:any) => {
        this.helper.openSnackBar(err.message+" Scan QR again if QR available & enter code","Ok")
      },
    });
    
  }
  submitCode(){
    this.cognitoUser.setUserMfaPreference(this.IMfaSettings, this.IMfaSettings,(err: any, session: any) => {
      if(session){
        console.log(session)
      }
      if(err){
          console.log(err.message)
      }
      });
      // this.showLoginForm=true;
  }
  loginWithOTP(){
    this.showLoading = true;
    this.cognitoUser.sendMFACode(this.authCode, {
    onSuccess: (session:any, userConfirmationNecessary:any) => {
      let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
            this.apiService.setAuthResult(session);
            // this.apiService.addLocalStorage('isAuthenticated', true);
            this.apiService.addLocalStorage('username', this.email_address.toLowerCase());
            this.apiService.addLocalStorage('domain', this.domain);
            this.apiService.addLocalStorage('role', tokenValue['custom:role'])
            this.orgId = tokenValue['custom:organizationid'];
            // this.authService.enableMfa(this.cognitoUser);
            if(this.showMfaCode){
              this.updatePhoneNoAfterDeviceRegister(tokenValue['custom:userPhoneNumber']); 
            }
            this.getClientMessage();
            if(tokenValue['custom:role'] === "Admin"){
              this.getNewTruckDetails();
            }
            this.getOrgDetails();
    },
    onFailure: (err:any) => {
      alert(err.message || JSON.stringify(err));
    },
  },
  this.mfaType,
  null);
  
}
enableMfa(){
  this.cognitoUser.enableMFA((err:any,result:any)=> {
    if(result){
      alert(result)
    }
    if(err){
      alert(err.message)
    }
  }) 

}
async updateLoginDetails() {
  var today = new Date();
  var url = 'user?username='+this.email_address.toLowerCase();
  var payload = {};
  payload['lastLoginDateTime']=today;
  this.apiService.updatedata(url, payload).subscribe(
    (res: any) => {
      this.updateBrowserDetails();
    }, (error) => {
      console.log(error);
    }
  )
}
async updateBrowserDetails() {
  var today = new Date();
  var url = 'browserDetails?frontendVersion='+environment.uiVersion;
  this.apiService.getByUrl(url).subscribe(
    (res: any) => {
      console.log(res)
      if(res){
        this.apiService.addLocalStorage('activeUserId',res.Id)
      }
    }, (error) => {
      console.log(error);
    }
  )
}
showWelcomeSplash(){
  var title = 'FleetPaths';
  
    const modalData = {};
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        data: modalData,
        type: 'splash'
      },
      minWidth: 250
    });
    confirmDialog.afterClosed().subscribe(result => {
      this.cookieService.set(
        'splash',
        'accepted',
        1000,
        '',
        '',
        true,
        'Strict',
      )
      if(this.domain === "fleetpaths"){
        // this.showSplash();
      }
    });
}
showSplash(){
  var title = 'Update';

  const modalData = {};
  const confirmDialog = this.dialog.open(EditComponent, {
    data: {
      title: title,
      data: modalData,
      type: 'splash'
    },
    minWidth: 250
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      console.log(result);
    }
  });
}
getReadableTypes(type: string){
  var re = /_/gi; 
  var newType = type.replace(re, " "); 
  return newType
}
backToLogin(){
  this.showMfaTypeSelect = false;
  this.showNewPassword = false;
  this.showSmsMfaForm = false;
  this.showLoginForm = true;
}
startCountdown() {
  this.secondsLeft = 30;
  this.isButtonDisabled = true;

  this.intervalId = setInterval(() => {
    this.secondsLeft--;

    if (this.secondsLeft === 0) {
      this.clearInterval();
    }
  }, 1000);
}
clearInterval() {
  clearInterval(this.intervalId);
  this.isButtonDisabled = false;
}
onSubmitSmsOtp(form: NgForm){
  this.resetSubmitted = true;
  if (form.valid) {
    this.isLoading = true;
    
    this.cognitoUser.sendMFASelectionAnswer(this.mfaTypeSelected, {
      onSuccess: function(result) {
        console.log('call result: ' + result);
      },
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
      },
      totpRequired: (challengeName: any, challengeParameters: any) => {
        this.mfaType = challengeName;
        this.showAuthCode=false;
        this.showMfaTypeSelect = false;
        this.showLoginForm = false;
        this.showMfaCode = true;
      },
      mfaRequired: (challengeName: any, challengeParameters: any) => {
        console.log("mfaRequired function ! "+challengeName)
        this.secondsLeft = 30;
        this.showMfaTypeSelect = false;
        this.showSmsMfaForm = true;
        this.startCountdown();
      },
    });
  }else {
    this.showError = true;
  }
}
async updatePhoneNoAfterDeviceRegister(userPhoneNumber: string){
    var url = 'user?username='+this.email_address.toLowerCase();
    var payload = {};
    payload['phoneNumber']=userPhoneNumber;
    this.apiService.updatedata(url, payload).subscribe(
      (res: any) => {
        console.log("Both MFA types enabled.")
      }, (error) => {
        console.log(error);
      }
    )
  }
}
