import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AngularMaterialModule } from '../../angular-material.module';
import { MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WINDOW_PROVIDERS } from '../../window.provider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { CalendarModule } from 'primeng-lts/calendar';
import { MessagesModule } from 'primeng-lts/messages';
import { MessageModule } from 'primeng-lts/message';
import { DialogModule } from 'primeng-lts/dialog';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import { ConfirmationService } from 'primeng-lts/api';
import { PaginatorModule } from 'primeng-lts/paginator';
import { TableModule } from 'primeng-lts/table';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { DropdownModule } from 'primeng-lts/dropdown';
import { TooltipModule } from 'primeng-lts/tooltip';
import { SidebarModule } from 'primeng-lts/sidebar';
import {SplitButtonModule} from 'primeng-lts/splitbutton';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import {MessageService} from 'primeng-lts/api';
import {environment} from 'src/environments/environment';
import { SignInComponent } from 'src/app/modules/sign-in/sign-in.component';
import { WebcamModule } from 'ngx-webcam';
import { ReportsComponent } from 'src/app/modules/reports/reports.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDrawingModule, AgmDrawingManager } from '@agm/drawing';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { TrucksComponent } from 'src/app/modules/trucks/trucks.component';
import { TruckTypesComponent } from 'src/app/modules/truck-types/truck-types.component';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { ColorPickerModule } from 'smart-webcomponents-angular/colorpicker';
// import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { AlertsComponent } from 'src/app/modules/alerts/alerts.component';
import { StatusComponent } from 'src/app/modules/status/status.component';
import { EditorModule } from 'primeng-lts/editor';
import { PublicMapSettingsComponent } from 'src/app/modules/public-map-settings/public-map-settings.component';
import { LogosComponent } from 'src/app/modules/logos/logos.component';
import { ImageDragDirective } from 'src/app/shared/directives/image-drag.directive';
import { PortalsComponent } from 'src/app/modules/portals/portals.component';
import { StreetPriorityComponent } from 'src/app/modules/street-priority/street-priority.component';
import { UsersComponent } from 'src/app/modules/users/users.component';
import { PublicComponent } from 'src/app/modules/public/public.component';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { ToastModule } from 'primeng-lts/toast';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ProgressBarModule} from 'primeng-lts/progressbar';
import { ForgotPasswordComponent } from 'src/app/modules/forgot-password/forgot-password.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartReportComponent } from 'src/app/modules/chart-report/chart-report.component';
import { StatisticsComponent } from 'src/app/modules/statistics/statistics.component';
import { InstructionComponent } from 'src/app/modules/instruction/instruction.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CookieService } from 'ngx-cookie-service';
import { StreetHighwayChangeComponent } from 'src/app/modules/street-highway-change/street-highway-change.component';
import { LoadStreetComponent } from 'src/app/modules/load-street/load-street.component';
import { JobsListComponent } from 'src/app/modules/jobs-list/jobs-list.component';
import { AllOrgsComponent } from 'src/app/modules/all-orgs/all-orgs.component';
import { ErrorInterceptor } from 'src/app/api/error-interceptor.service';
import { MoreInfoComponent } from 'src/app/modules/more-info/more-info.component';
import { RouteMapComponent } from 'src/app/modules/route-map/route-map.component';
import { TransportPublicComponent } from 'src/app/modules/transport-public/transport-public.component';
import { ServiceControlComponent } from 'src/app/modules/service-control/service-control.component';
import { JasperReportComponent } from 'src/app/modules/jasper-report/jasper-report.component';


@NgModule({
  declarations: [
    DefaultComponent,
    SignInComponent,
    ReportsComponent,
    TrucksComponent,
    RouteMapComponent,
    TransportPublicComponent,
    ForgotPasswordComponent,
    TruckTypesComponent,
    AlertsComponent,
    StatusComponent,
    PublicMapSettingsComponent,
    LogosComponent,
    PortalsComponent,
    StreetPriorityComponent,
    UsersComponent,
    PublicComponent,
    ChartReportComponent,
    StatisticsComponent,
    InstructionComponent,
    StreetHighwayChangeComponent,
    LoadStreetComponent,
    JobsListComponent,
    AllOrgsComponent,
    MoreInfoComponent,
    ServiceControlComponent,
    JasperReportComponent
    // ImageDragDirective
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule,
    WebcamModule,
    SharedModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AngularMaterialModule,
    // GoogleMapsModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    ProgressBarModule,
    CalendarModule,
    MessagesModule,
    MessageModule,
    InputSwitchModule,
    DialogModule,
    ConfirmDialogModule,
    PaginatorModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
    SidebarModule,
    SplitButtonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYEw3YiYG_952mOPRFpe-H4ZJaXsEV2ng',
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmDrawingModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmMarkerClustererModule,
    NgxMatDatetimePickerModule,NgxMatTimepickerModule,
    NgxMatMomentModule,
    OverlayPanelModule,
    ColorPickerModule,
    ColorSketchModule,
    EditorModule,
    ToastModule,
    QRCodeModule,
    PdfViewerModule
    // NgxEditorModule
  ],
  providers: [CookieService,AgmDrawingManager,
    DashboardService, ConfirmationService, MessageService,DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }
    // {provide: Window, useFactory: windowFactory}
  ]
})
export class DefaultModule { }
